import React, { useState, useEffect } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import NoticeBoard from "../asset/images/home/notice-board-gradient.svg";
import { Link } from "react-router-dom";
import axios from 'axios';

import { SERVER_URL } from "../../src/config";
const BASE_URL = SERVER_URL;

const Notice = () => {

	// Owl carousel settings
	const settings = {
		autoplay: true,
		autoplayTimeout: 4000,
		autoplaySpeed: 5000,
		loop: true,
		margin: 30,
		nav: false,
		dots: true,
		responsive: {
			0: {
				items: 1
			},
			768: {
				items: 1
			},
			992: {
				items: 2
			}
		}
	}

	const [notices, setNotices] = useState([]);
	const [error, setError] = useState('');


	// handle 429 too many request issue by using Request Queue
	class RequestQueue {
		constructor() {
			this.queue = [];
			this.isProcessing = false;
		}

		enqueue(requestFunction) {
			this.queue.push(requestFunction);
			this.processQueue();
		}

		async processQueue() {
			if (this.isProcessing) return;

			this.isProcessing = true;

			while (this.queue.length > 0) {
				const requestFunction = this.queue.shift();
				await requestFunction();
			}

			this.isProcessing = false;
		}
	}

	const requestQueue = new RequestQueue();

	// const getNoticeData = async () => {
	// 	return new Promise((resolve, reject) => {
	// 		requestQueue.enqueue(async () => {
	// 			try {
	// 				const token = await localStorage.getItem('token');
	// 				const response = await axios.get(`${BASE_URL}/notice-board`, {

	// 				});

	// 				setNotices(response.data.data);

	// 				resolve(response.data);
	// 			} catch (error) {
	// 				if (error.response?.status === 429) {
	// 					console.warn('429 Too Many Requests. Retrying in 1 second...');
	// 					setTimeout(() => {
	// 						getNoticeData().then(resolve).catch(reject);
	// 					}, 1000);
	// 				} else {
	// 					reject(new Error(error.response?.data?.message || error.message));
	// 				}
	// 			}
	// 		});
	// 	});
	// };

	// useEffect(() => {
	// 	getNoticeData();
	// }, []);

	const getNoticeData = async () => {
		try {
		  const token = await localStorage.getItem('token');
		  const response = await axios.get(`${BASE_URL}/notice-board`);
		  setNotices(response.data.data);
		} catch (error) {
		  if (error.response?.status === 429) {
			console.warn('429 Too Many Requests. Retrying in 1 second...');
			setTimeout(getNoticeData, 1000); // Retry after 1 second
		  } else {
			console.error(error.message || 'An error occurred');
		  }
		}
	  };

	  useEffect(() => {
		getNoticeData();
	  }, []);


    const dateBgColors = ['#C7EFFC', '#FFEFB9', '#d4ffb9', '#c2f2e8'];
    const cardBgColors = ['#EBFAFF', '#FFF7DD', '#efffdd', '#E3FFF3'];

	return (<>
		<section className="py-5 position-relative">
			<img
				src={NoticeBoard}
				alt="Digitaledupark" width="1920" height="354"
				className="img-fluid object-fit-cover position-absolute bottom-0 start-0 z-0 w-100 h-100"
			/>
			<div className="container py-sm-4 position-relative z-1 ">
				<div className="row g-0 mb-md-3">
					<div className="col-md-8">
						<h2 className="fs-1 text-primary fw-bold">Notice Board</h2>
					</div>
					<div className="col-md-4 text-end d-none d-md-block">
						<Link to="/notice-board" className="btn btn-primary blue animate-btn" data-text="View all Notices" aria-label="View all Notices">
							<span>View all Notices</span>
						</Link>
					</div>
				</div>
				<p>
					It is an exceptional online test platform for conducting online exams and solving all your preparation doubts. It is a smooth online examination system to use and enhances the flow of information.
				</p>

				{/* <OwlCarousel {...settings} loop className="owl-carousel noticeCarousel">
					{notices && notices.length > 0 ? (
						notices.map((notice, index) => {
								// Format the date dynamically
								const formattedDate = new Date(notice.notice_date).toLocaleDateString("en-GB", {
									day: '2-digit',
									month: 'short'
								});
							return (
								<div
									key={index}
									className="notice__card d-flex rounded-2 align-items-center"
									style={{
										'--date-bg': dateBgColors[index % dateBgColors.length],
										'--card-bg': cardBgColors[index % cardBgColors.length]
									}}
								>
									<div className="date fs-3 fw-medium text-dark p-3 text-center rounded-1 lh-1">
										{formattedDate}
									</div>
									<div className="col ps-3">
										<h5 className="fs-5 mb-0" dangerouslySetInnerHTML={{ __html: notice.title }}></h5>
										<div className="fs-15 mb-0" dangerouslySetInnerHTML={{ __html: notice.description }}></div>
									</div>
								</div>
							);
						})
					) : (
						<div className="notice__card d-flex justify-content-center align-items-center">
							<p className="fs-5 mb-0">Notice not available!</p>
						</div>
					)}
					
				</OwlCarousel> */}

				{notices && notices.length > 0 ? (
				<OwlCarousel {...settings} loop className="owl-carousel noticeCarousel">
					{notices.map((notice, index) => {
					const formattedDate = new Date(notice.notice_date).toLocaleDateString("en-GB", {
						day: '2-digit',
						month: 'short'
					});
					return (
						<div
						key={index}
						className="notice__card d-flex rounded-2 align-items-center"
						style={{
							'--date-bg': dateBgColors[index % dateBgColors.length],
							'--card-bg': cardBgColors[index % cardBgColors.length]
						}}
						>
						<div className="date fs-3 fw-medium text-dark p-3 text-center rounded-1 lh-1">
							{formattedDate}
						</div>
						<div className="col ps-3">
							<h5 className="fs-5 mb-0" dangerouslySetInnerHTML={{ __html: notice.title }}></h5>
							<div className="fs-15 mb-0" dangerouslySetInnerHTML={{ __html: notice.description }}></div>
						</div>
						</div>
					);
					})}
				</OwlCarousel>
				) : (
				<div className="notice__card d-flex justify-content-center align-items-center">
					<p className="fs-5 mb-0">Notice not available!</p>
				</div>
				)}
			</div>
		</section>

	</>)
}
export default Notice;